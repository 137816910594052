import styled from "@emotion/styled";

const AboutUsTitle = styled.h1`
  font-family: Roboto;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 1.75px;
  color: #f38f1d;
  text-transform: uppercase;
  margin-bottom: 41px;
`;

const AboutUsParagraph = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
`;

const ConceptWrapper = styled.div`
  height: 100%;
  background-color: #f38f1d;
  padding: 40px 32px 90px 32px;
`;

const ConceptsTitle = styled.h2`
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 50px;
`;

const ConceptSubtitle = styled.h3`
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 36px;
`;

const ConceptParagraph = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: 0;
`;

const ImageIndicatorsRow = styled.div`
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 24px;
`;

const ImageIndicator = styled.div<{ active: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  background-color: ${props => (props.active ? "black" : "white")};
`;

const DownloadLinkRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 50px;
  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 450px) {
    justify-content: flex-end;
  }

  & > span {
    @media (max-width: 450px) {
      font-size: 16px;
      margin-right: 15px;
    }
  }
`;

const DownloadLink = styled.span`
  display: block;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  margin-right: 25px;
  float: right;
`;

const DownloadLinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1.45rem;
`;

const DiamondShapeWrapper = styled.div`
  width: 300px;
  height: 300px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 30px;
  transform-origin: 50% 50%;
  transform: rotate(45deg) translateY(10px);
`;

const DiamondShape = styled.div`
  width: 405px;
  height: 405px;
  transform: rotate(-45deg) translateY(-74px);
`;

const DiamondImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const styles = {
  AboutUsTitle,
  AboutUsParagraph,
  ConceptWrapper,
  ConceptsTitle,
  ConceptSubtitle,
  ConceptParagraph,
  ImageIndicator,
  ImageIndicatorsRow,
  DownloadLink,
  DownloadLinkRow,
  DownloadLinksColumn,
  DiamondShapeWrapper,
  DiamondShape,
  DiamondImage
};

export default styles;
