import { Container, Row, Col, Visible } from "react-grid-system";
import React, { FunctionComponent, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import SwipeableViews from "react-swipeable-views";
import ReactPlayer from "react-player";
import { useIntl } from "react-intl";
import { graphql } from "gatsby";

import { Layout } from "../components";
import Preview from "../components/common/Preview";

import styles from "../styles/about-us";

interface AboutUsPageProps extends RouteComponentProps {
  data: {
    pdfIcon: { publicURL: string };
    prismicAboutUsPage: {
      data: {
        cover: { alt: string; url: string };
        about_us_paragraph_part_one: { html: string };
        about_us_paragraph_part_two: { html: string };
        files: Array<{
          pdf_title: { text: string };
          pdf_file: { url: string; raw: { name: string } };
        }>;
        video: {
          type: string;
          embed_url: string;
          thumbnail_url: string;
        };
        decunify_images: Array<{
          img: { url: string; alt: string };
        }>;
        concepts_title: { text: string };
        concepts: Array<{
          concept_subtitle: { text: string };
          concept_paragraph: { html: string };
        }>;
        title?: string;
        description?: string;
        keywords: string;
        canonical_url?: {
          url: string;
        };
        image?: {
          alt: string;
          url: string;
        };
      };
    };
  };
}

const AboutUsPage: FunctionComponent<AboutUsPageProps> = ({ data }) => {
  const { formatMessage } = useIntl();
  const [currImgIndex, setCurrImgIndex] = useState<number>(0);

  const {
    AboutUsTitle,
    AboutUsParagraph,
    ConceptWrapper,
    ConceptsTitle,
    ConceptSubtitle,
    ConceptParagraph,
    ImageIndicator,
    ImageIndicatorsRow,
    DownloadLink,
    DownloadLinkRow,
    DownloadLinksColumn
  } = styles;

  const handleSelectIndicator = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    selectedIndex: number
  ) => {
    e?.preventDefault();
    setCurrImgIndex(selectedIndex);
  };

  return (
    <Layout
      underline="about-us"
      metaTitle={
        data.prismicAboutUsPage.data.title ||
        formatMessage({ id: "pages.about-us.meta-title" })
      }
      metaDescription={
        data.prismicAboutUsPage.data.description ||
        formatMessage({ id: "pages.about-us.meta-description" })
      }
      metaKeywords={
        data.prismicAboutUsPage.data.keywords ||
        formatMessage({ id: "pages.about-us.meta-keywords" })
      }
      metaURL={data.prismicAboutUsPage.data.canonical_url?.url}
      metaImage={data.prismicAboutUsPage.data.image}
    >
      <Container>
        <Row style={{ marginTop: "50px", marginBottom: "117px" }}>
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <Visible xs sm>
                  <AboutUsTitle style={{ textAlign: "center" }}>
                    {formatMessage({ id: "about-us" })}
                  </AboutUsTitle>
                </Visible>
                <Visible md lg xl>
                  <AboutUsTitle>
                    {formatMessage({ id: "about-us" })}
                  </AboutUsTitle>
                </Visible>
              </Col>
              <Col xs={12}>
                <AboutUsParagraph
                  dangerouslySetInnerHTML={{
                    __html:
                      data.prismicAboutUsPage.data.about_us_paragraph_part_one
                        .html
                  }}
                />
              </Col>
              <Col xs={12}>
                <AboutUsParagraph
                  dangerouslySetInnerHTML={{
                    __html:
                      data.prismicAboutUsPage.data.about_us_paragraph_part_two
                        .html
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: "133px" }}>
          <Col xs={12} lg={8} className="about-us-images">
            <SwipeableViews
              index={currImgIndex}
              onChangeIndex={index => setCurrImgIndex(index)}
            >
              {data.prismicAboutUsPage.data.decunify_images.map(
                ({ img }, index) => (
                  <img
                    alt={img.alt}
                    src={img.url}
                    key={`image#${index}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                )
              )}
            </SwipeableViews>
            <ImageIndicatorsRow>
              {data.prismicAboutUsPage.data.decunify_images.map((_, index) => (
                <a
                  key={`indicator#${index}`}
                  onClick={e => handleSelectIndicator(e, index)}
                  style={{
                    cursor: "pointer",
                    marginRight:
                      index !==
                      data.prismicAboutUsPage.data.decunify_images.length - 1
                        ? "17px"
                        : "0"
                  }}
                >
                  <ImageIndicator active={index === currImgIndex} />
                </a>
              ))}
            </ImageIndicatorsRow>
          </Col>
          <Col xs={12} lg={4} className="about-us-pdf">
            {data.prismicAboutUsPage.data.files &&
            <DownloadLinksColumn>
              {data.prismicAboutUsPage.data.files.map((file, index) => (
                <a
                  target="_blank"
                  key={`file#${index}`}
                  rel="noopener noreferrer"
                  href={file.pdf_file.url}
                  style={{
                    textDecoration: "none",
                    marginBottom:
                      index !== data.prismicAboutUsPage.data.files.length - 1
                        ? "20px"
                        : 0
                  }}
                >
                  <DownloadLinkRow>
                    <DownloadLink>{file.pdf_title.text}</DownloadLink>
                    <img
                      className="pdf-icons"
                      alt={file.pdf_file.raw.name}
                      src={data.pdfIcon.publicURL}
                    />
                  </DownloadLinkRow>
                </a>
              ))}
            </DownloadLinksColumn>
            }
          </Col>
          <Col
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <ReactPlayer
              controls
              url={data.prismicAboutUsPage.data.video.embed_url}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "150px" }}>
          <Col xs={12}>
            <Visible xs sm>
              <ConceptsTitle style={{ textAlign: "center" }}>
                {data.prismicAboutUsPage.data.concepts_title.text}
              </ConceptsTitle>
            </Visible>
            <Visible md lg xl>
              <ConceptsTitle>
                {data.prismicAboutUsPage.data.concepts_title.text}
              </ConceptsTitle>
            </Visible>
          </Col>
          {data.prismicAboutUsPage.data.concepts.map((concept, index) => (
            <Col
              xs={12}
              lg={4}
              key={`concept#${index}`}
              style={{ marginBottom: "50px" }}
            >
              <ConceptWrapper>
                <ConceptSubtitle>
                  {concept.concept_subtitle.text}
                </ConceptSubtitle>
                <ConceptParagraph
                  dangerouslySetInnerHTML={{
                    __html: concept.concept_paragraph.html
                  }}
                />
              </ConceptWrapper>
            </Col>
          ))}
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!) {
    prismicAboutUsPage(lang: { eq: $lang }) {
      data {
        title
        description
        keywords
        image {
          url
          alt
        }
        canonical_url {
          url
        }
        cover {
          alt
          url
        }
        about_us_paragraph_part_one {
          html
        }
        about_us_paragraph_part_two {
          html
        }
        files {
          pdf_title {
            text
          }
          pdf_file {
            raw
            url
          }
        }
        video {
          type
          embed_url
          thumbnail_url
        }
        decunify_images {
          img {
            url
            alt
          }
        }
        concepts_title {
          text
        }
        concepts {
          concept_subtitle {
            text
          }
          concept_paragraph {
            html
          }
        }
      }
    }
    pdfIcon: file(relativePath: { eq: "icons/pdf-icon-orange.svg" }) {
      publicURL
    }
  }
`;

export default Preview(AboutUsPage);
